<template>
    <div>
        <h2>เครื่อง POS</h2>
        <v-card class="mt-4 px-6 pt-6">
            <v-text-field
              outlined
              v-model="datatable.search"
              placeholder="ค้นหา ..."
              dense
            ></v-text-field>
        </v-card>
        <v-card class="mt-4">
          <div class="d-flex pa-4">
            <!--
            <v-btn              
              @click="deleteMultiple()"
              :disabled="datatable.selectedItems.length == 0"
            ><v-icon>{{ mdiDeleteOutline }}</v-icon></v-btn>        
            -->
            <v-btn color="primary" class="ml-auto" @click="$router.push({ 'name': 'device_create', params: { device_id: 'create' } })">เพิ่มช่องทาง</v-btn>
          </div>
          <delay-datatable
            :headers="datatable.headers"
            :url="'/possetting/device/list'"            
            :queryParams="getDatatableQueryParams"
            :options.sync="datatable.options"
            :isSaveState="true"
            @loadState="loadDatatableState"
            v-model="datatable.selectedItems"
            ref="mainDatatable"
          >

          <template          
            v-slot:item.channel_type="{ item }"
          >
            <div class="d-flex align-center">
              <v-img 
              v-if="!!helpers.convertChannelTypeToImage(item.channel_type)" 
              :src="helpers.convertChannelTypeToImage(item.channel_type)"
              :max-width="25"
              class="mr-2"
              ></v-img> {{ helpers.convertChannelTypeToText(item.channel_type) }}               
            </div>            
          </template>

          <template          
            v-slot:item.warehouse="{ item }"
          >
            {{ !!item.warehouse ? item.warehouse.name : '-' }}               
          </template>

          <template          
            v-slot:item.address="{ item }"
          >
            <div>{{ item.branch }}</div>
            <div>{{ item.address }} {{ item.city }} {{ item.county }} {{ item.province }} {{ item.postcode }}</div>            
            <div>{{ item.tel }}</div>
          </template>
          
          <template          
            v-slot:item.actions="{ item }"
          >   
            <v-btn
              icon
              @click="$router.push({name: 'device_create', params: { device_id: item.id }})"
            ><v-icon>{{ $store.getters['auth/GET_ABILITY'].can('update', 'POSSetting') ? icons.mdiPlaylistEdit : icons.mdiEyeOutline }}</v-icon></v-btn>
            <v-btn
              icon
              @click="showDeleteDialog(item.id, item.name)"
            ><v-icon>{{ icons.mdiDeleteOutline }}</v-icon></v-btn>
          </template>
          </delay-datatable>
        </v-card>        
        <v-dialog
          transition="dialog-top-transition"
          max-width="600"
          v-model="deleteDialog.show"
          persistent
        >
          <v-card>
            <v-toolbar
              color="primary"
              dark
              class="text-h4"
            >ยืนยันที่จะลบ ? {{ deleteDialog.targetName }}</v-toolbar>

            <v-form v-model="deleteDialog.formValid">
              <v-card-text class="pa-12">
                <v-container>
                  <v-row>
                    <v-col>
                      <v-radio-group
                        v-model="deleteDialog.deleteOptions.isMove"
                        column
                      >
                        <v-radio
                          :label="'ฉันต้องการย้ายข้อมูลที่เกี่ยวข้องกับ ' + deleteDialog.targetName + ' ไปไว้ที่เครื่องอื่น'"
                          :value="true"
                        ></v-radio>
                        <v-radio
                          label="ฉันต้องการลบข้อมูลทั้งหมดที่เกี่ยวข้องกับเครื่อง POS นี้"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <template v-if="deleteDialog.deleteOptions.isMove">
                    <v-row>
                      <v-col>
                        <delay-autocomplete
                          outlined
                          hide-selected
                          cache-items
                          v-model="deleteDialog.deleteOptions.targetId"
                          label="เครื่อง POS ที่ต้องการย้ายไป"
                          placeholder="พิมพ์เพื่อค้นหาเครื่อง POS"
                          no-data-text="ไม่พบข้อมูล"
                          :url="'/possetting/device/' + deleteDialog.targetId + '/moveList'"
                          dense                        
                          item-text="name"
                          item-value="id"
                          :rules="[validators.required]"
                        ></delay-autocomplete>
                      </v-col>
                    </v-row>                    
                  </template>
                  <v-row v-else>
                    <v-col>
                      <v-checkbox
                        v-model="deleteDialog.deleteOptions.lastConfirm"
                        :rules="[validators.required]"
                        label="ฉันเข้าใจและยอมรับ ว่าจะไม่สามารถเข้าถึงข้อมูลที่ถูกลบได้อีก"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>              
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn
                  large
                  color="primary"
                  :disabled="!deleteDialog.formValid"
                  :loading="deleteDialog.loading"
                  @click="deleteSingle()"
                >ลบ</v-btn>
                <v-btn
                  large
                  color="secondary"
                  text
                  @click="deleteDialog.show = false"
                  :disabled="deleteDialog.loading"
                >ยกเลิก</v-btn>
                <!--
                <v-btn
                  v-for="(btn, btn_index) in $store.state.app.confirmDialog.buttons"
                  :key="btn_index"
                  large
                  :color="btn.color"
                  :text="btn.color == 'secondary'"
                  @click="callbackConfirmDialogButton(btn.result)"
                >{{ btn.text }}</v-btn>
                -->
              </v-card-actions>
            </v-form>            
          </v-card>
        </v-dialog>
    </div>
</template>
<script>
import ProductImage from '@/components/ProductImage.vue'
import { asyncDelete } from '@/helpers/asyncAxios'
import { convertChannelTypeToImage, convertChannelTypeToText } from '@/helpers/converter.js'
import { required } from '@core/utils/validation'
import { mdiDeleteOutline, mdiEyeOutline, mdiPlaylistEdit } from '@mdi/js'
export default {
  data() {
    return {
      datatable: {
        options: {
          page: 1,
          itemsPerPage: 10,
        },
        selectedItems: [],
        search: null,
        headers: [
          {
            text: 'ชื่อ',
            value: 'name',
          },
          {
            text: 'เชื่อมกับคลัง',
            value: 'warehouse',
            sortable: false,
          },
          {
            text: 'ที่อยู่',
            value: 'address',
            sortable: false,
          },
          {
            text: '#',
            value: 'actions',
            sortable: false,
            //align: 'center',
          },
        ],
      },
      deleteDialog: {
        show: false,
        loading: false,
        formValid: false,
        targetName: null,
        targetId: null,
        targetType: 'manual',
        deleteOptions: {
          isMove: true,
          targetId: null,
          lastConfirm: false,
        },
      },
      icons: {
        mdiDeleteOutline,
        mdiPlaylistEdit,
        mdiEyeOutline,
      },
      helpers: {
        convertChannelTypeToText,
        convertChannelTypeToImage,
      },
      validators: {
        required,
      },
    }
  },
  computed: {
    getDatatableQueryParams() {
      return {
        search: this.datatable.search,
      }
    },
  },
  methods: {
    loadDatatableState({ options, queryParams }) {
      this.datatable = { ...this.datatable, options: { ...options }, ...queryParams }
    },
    async deleteSingle() {
      this.deleteDialog.loading = true
      try {
        const result = await asyncDelete('/possetting/device/' + this.deleteDialog.targetId, {
          targetId: this.deleteDialog.deleteOptions.targetId,
          isMove: this.deleteDialog.deleteOptions.isMove,
        })

        this.$refs.mainDatatable.reload()
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.deleteDialog.loading = false
      this.deleteDialog.show = false
    },
    async showDeleteDialog(id, name) {
      this.deleteDialog.show = true

      this.deleteDialog.deleteOptions.isMove = true
      this.deleteDialog.deleteOptions.targetId = null
      this.deleteDialog.deleteOptions.lastConfirm = false

      this.deleteDialog.targetId = id
      this.deleteDialog.targetName = name
    },
  },
  components: {
    ProductImage,
  },
}
</script>